@import 'src/misc/styles/mixins';
@import 'src/misc/styles/colors';

.jobsWrapper {
  max-width: inherit;
  width: inherit;

  .pageTitle {
    @include defaultHeader;
  }

  .offersTitle {
    margin: 2rem 0;
    font-size: 1.5rem;
  }

  .offer {
    margin-bottom: 4rem;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      color: $default_navy;
      font-size: 1.25rem;
      hyphens: auto;
      word-break: break-word;
      font-weight: $default_fontBoldWeight;
    }

    .department {
      color: $default_navy;
      font-size: 1rem;
      hyphens: auto;
      margin: 0 0 1rem;
      word-break: break-word;
    }
  }
}
