@import 'src/misc/styles/mixins';

.aboutWrapper {
  .pageTitle {
    @include defaultHeader;
  }

  .paragraph {
    @include topBottomMargins;
    @include defaultParagraph;
  }
}
