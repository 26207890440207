@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.pageWrapper {
  align-items: flex-start;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $default_contentWidth;
  overflow-x: hidden;
  padding: 0 2rem;
  position: relative;
  width: 100%;
}
