/*
 * NUMBERS
 */
$infinity: -9999999;
$lineSize: 1px;
$size: 16;

/*
 * DEFAULT
 */
$default_animationTime: 250ms;
$default_animationTimeLong: 750ms;
$default_animationTimeShort: 100ms;
$default_contentWidth: 64rem;
$default_font: 400 16px 'trajan-pro-3', serif;
$default_fontParagraph: serif;
$default_fontBoldWeight: 700;
$default_solidBorder: 1px solid;
$default_transition: all 250ms linear 0ms;
$default_shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);

/*
 * NAVBAR
 */
$navbar_height: 5rem;
$navbar_stickyHeight: 4rem;
$navbar_width: 100%;
