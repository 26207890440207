@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

:global(.mobile) {
  .estateWrapper {
    .criteriaWrapper {
      .criteria {
        .criteriaList {
          padding-left: 1rem;
        }
      }
    }

    .locationsWrapper {
      .column {
        align-items: center;
        justify-content: center;
        min-width: 100%;
        padding: 0;

        &:last-child {
          min-width: 100%;
          padding-left: 0;
        }
      }
    }

    .propositionContainer {
      padding: 1rem;
    }
  }
}

.estateWrapper {
  max-width: inherit;
  width: inherit;

  .pageTitle {
    @include defaultHeader;
  }

  .paragraph {
    @include simpleParagraph;
    margin: 1rem 0;
  }

  .criteriaWrapper {
    list-style-type: upper-roman;
    padding-left: 1.5rem;

    .criteria {
      margin-top: 2rem;

      .criteriaTitle {
        color: $estate_titleColor;
        text-align: left;
      }

      .criteriaList {
        list-style-type: circle;
        margin-top: 1rem;
        padding-left: 3rem;

        .criteriaItem {
          .itemText {
            @include simpleParagraph;
          }
        }
      }

      .separator {
        @include separator;
      }
    }
  }

  .propositionContainer {
    border: $estate_borderColor 1px dashed;
    margin: 4rem auto 6rem;
    padding: 2rem 4rem;
    text-align: center;

    p {
      margin-bottom: 1rem;
    }

    a {
      @include simpleParagraph;
      color: $estate_mailLink;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .locationsWrapper {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    .column {
      align-items: center;
      display: flex;
      flex: 1 1 50%;
      flex-direction: column;
      justify-content: center;
      padding-right: 1rem;

      &:last-child {
        padding-left: 1rem;
        padding-right: 0;
        text-align: center;
      }

      &.mapContainer {
        min-height: 30rem;
        width: 100%;
      }
    }
  }

  .linkDescription {
    margin: 2rem 0 0;
  }

  .buttonLink {
    @include defaultButton;
    display: block;
    margin: 2rem 0 0;
  }
}
