/*
 * GLOBAL COLORS
 */

$default_black: #121212;
$default_blackAlpha: rgba(0, 0, 0, 0.95);
$default_white: #fff;
$default_whiteAlpha: rgba(255, 255, 255, 0.95);

$default_greyLight: #ccc;
$default_grey: #5c5c5c;
$default_greyDark: #2d2d2d;
$default_greyDarken: #2d2d2d;

$default_alphaDarken: rgba(0, 0, 0, 0.05);
$default_alphaDarkenMore: rgba(0, 0, 0, 0.69);
$default_alphaLighten: rgba(255, 255, 255, 0.1);
$default_alphaLightenMore: rgba(255, 255, 255, 0.69);

$default_navy: #0b2b4d;

/*
 * DEFAULT
 */
$default_bodyBackgroundColor: $default_white;
$default_textColor: $default_black;
$default_invertedTextColor: $default_white;

/*
 * DEFAULT BUTTON
 */
$defaultButton_background: $default_white;
$defaultButton_text: $default_navy;
$defaultButton_border: $default_navy;
$defaultButton_backgroundHover: $default_navy;
$defaultButton_textHover: $default_white;
$defaultButton_borderHover: $default_navy;
$defaultButton_disabled: $default_greyLight;
$defaultButton_disabledText: $default_white;

/*
 * BREADCRUMBS
 */
$breadcrumbs_arrow: $default_navy;
$breadcrumbs_link: $default_navy;

/*
 * COLLAPSE MENU
 */
$collapseMenu_background: $default_navy;
$collapseMenu_textColor: $default_white;
$collapseMenu_textColorHover: $default_white;
$collapseMenu_socialBorder: $default_alphaLighten;

/*
 * CONTACT ICON
 */
$contactIcon_strokeColor: $default_navy;
$contactIcon_fillWhite: $default_white;
$contactIcon_fillDesktop: $default_white;
$contactIcon_fillMobile: $default_white;

/*
 * CONTACT
 */
$contact_headerColor: $default_navy;
$contact_linkColor: $default_navy;

/*
 * ESTATE
 */
$estate_mailLink: $default_navy;
$estate_borderColor: $default_navy;
$estate_titleColor: $default_navy;

/*
 * FOOTER
 */
$footer_backgroundColor: $default_navy;
$footer_copyrightsColor: $default_alphaLighten;
$footer_textColor: $default_alphaLightenMore;
$footer_textColorHover: $default_white;
$footer_linkBorderColor: $default_alphaLighten;

/*
 * FORM
 */
$form_inputBorder: $default_alphaDarken;

/*
 * HORIZONTAL CARD
 */
$horizontalCard_linkTextColor: $default_navy;
$horizontalCard_textColor: $default_grey;
$horizontalCard_titleColor: $default_navy;
$horizontalCard_iconColor: $default_navy;

/*
 * MAIN MENU
 */
$mainMenu_background: $default_whiteAlpha;
$mainMenu_backgroundHover: $default_white;
$mainMenu_linkColor: $default_navy;
$mainMenu_linkHoverColor: $default_navy;
$mainMenu_linkAccent: $default_greyLight;
$mainMenu_linkHoverAccent: $default_navy;

/*
 * MAP MARKER
 */
$mapMarker_background: $default_white;
$mapMarker_border: $default_navy;

/*
 * NAVBAR
 */
$navbar_background: $default_whiteAlpha;
$navbar_backgroundSticky: $default_whiteAlpha;
$navbar_burgerMenuLink: $default_navy;
$navbar_burgerMenuLinkOpen: $default_navy;
$navbar_link: $default_white;
$navbar_linkActive: $default_greyDark;
$navbar_linkBorder: $default_black;
$navbar_shadowColor: $default_alphaDarken;

/*
 * PRESS
 */
$press_cardBorder: $default_alphaDarken;
$press_cardTitle: $default_navy;
$press_cardParagraph: $default_black;

/*
 * SOCIAL
 */
$social_icon: $default_navy;

/*
 * TOGGLE
 */
$toggle_borderColor: $default_alphaDarken;
$toggle_switchBackgroundColor: $default_white;
