@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.contactForm {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  margin: 2rem 0 0;
  width: 100%;

  label {
    @include topBottomMargins;
    display: flex;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .inputField {
    appearance: none;
    border: $default_solidBorder $form_inputBorder;
    font-family: $default_fontParagraph;
    font-size: 0.875rem;
    padding: 0.25rem;
    resize: none;
    width: 100%;
  }

  .checkbox {
    margin: 0.5rem 0 2rem;
    p {
      font-size: 0.75rem;
      line-height: 1;
      margin-left: 0.5rem;
    }

    a {
      color: $contact_linkColor;
      font-weight: $default_fontBoldWeight;
    }
  }

  .submitButton {
    @include defaultButton;
  }
}
