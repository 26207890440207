@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.burgerWrapper {
  cursor: pointer;
  display: block;
  margin: auto 1rem;
  position: relative;
  z-index: 3;

  span {
    background: $navbar_burgerMenuLink;
    display: block;
    height: 1px;
    margin-bottom: 0.45rem;
    outline: none;
    position: relative;
    transition: all ease-in-out 0.2s;
    width: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .open > span {
    background: $navbar_burgerMenuLinkOpen;
  }

  .open {
    &.invert > span {
      background: $navbar_burgerMenuLink;
    }
  }

  .open span:nth-child(2) {
    opacity: 0;
  }

  .open span:nth-child(3) {
    top: -8px;
    transform: rotate(45deg);
  }

  .open span:nth-child(1) {
    top: 8px;
    transform: rotate(-45deg);
  }
}
