@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.collapseMenuWrapper {
  background: $collapseMenu_background;
  bottom: 0;
  color: $collapseMenu_textColor;
  display: flex;
  flex-flow: column;
  font-size: 1.5rem;
  justify-content: space-between;
  left: 0;
  max-width: 100%;
  min-width: 100%;
  overflow-y: scroll;
  padding: $navbar_height 1rem 0;
  position: fixed;
  top: 0;
  will-change: transform;
  z-index: 1;

  ul.navLinks {
    align-self: center;
    display: flex;
    flex: 1;
    list-style-type: none;
    text-align: left;
    flex-flow: column;
    width: max-content;

    li.link {
      margin-top: 1rem;

      a {
        border-bottom: $default_solidBorder transparent;
        color: $collapseMenu_textColor;
        cursor: pointer;
        letter-spacing: 0.1rem;
        line-height: 2;
        opacity: 0.8;
        text-decoration: none;
        transition: $default_transition;

        &.active {
          border-bottom: $default_solidBorder $navbar_linkActive;
        }

        &:hover {
          border-bottom: $default_solidBorder $collapseMenu_textColorHover;
          color: $collapseMenu_textColorHover;
          opacity: 1;
        }
      }
    }

    li.dropdownLink {
      font-size: 75%;
      margin-top: 0.5rem;
    }
  }

  .social {
    align-items: center;
    border-top: $collapseMenu_socialBorder 1px double;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 1.5rem;
    padding: 1.5rem;

    a {
      display: flex;
      font-size: 2rem;
      margin: 0 1rem;

      &.icon {
        color: $collapseMenu_textColor;
        opacity: 0.8;
        transition: opacity $default_animationTimeLong linear 0s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

:global(.tablet) {
  .collapseMenuWrapper {
    min-width: 50%;
  }
}
