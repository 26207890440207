@import 'src/misc/styles/mixins';

.messageWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 33vh;
  width: 100%;

  .backButton {
    @include defaultButton;
    margin-top: 2rem;
  }
}
