@import 'src/misc/styles/animations';
@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

:global(.mobile) {
  .singlePageWrapper {
    .carousel {
      .imageContainer {
        height: 33vh;
      }
    }
  }
}

.singlePageWrapper {
  .pageTitle {
    @include defaultHeader;
  }

  .paragraph {
    @include topBottomMargins;
    @include defaultParagraph;
  }

  .buttonLink {
    @include defaultButton;
    display: block;
    margin: 1rem auto;
  }

  .carousel {
    margin-bottom: 2rem;
    width: 100%;

    .imageContainer {
      height: 32rem;
      width: 100%;

      .image {
        @include objectFitImage;
      }
    }
  }

  .social {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    transition: $default_transition;
    width: 100%;

    .icon {
      color: $social_icon;
      font-size: 2rem;
      margin: 2rem 0.5rem 0;

      &:hover {
        animation: blinking $default_animationTimeShort ease-in-out 5;
      }

      &:active {
        transform: translateY(0.15rem);
      }
    }
  }
}
