@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.tenantWrapper {
  .pageTitle {
    @include defaultHeader;
  }

  .paragraph {
    @include simpleParagraph;
    margin: 1rem 0;
  }

  .pageSubtitle {
    @include defaultSubHeader;
    margin-top: 2rem;
  }

  ul.list {
    display: flex;
    flex-flow: column;
    list-style: disc;
    margin: 0;

    li {
      @include simpleParagraph;
      color: $default_black;
      display: list-item;
      margin-top: 1rem;

      &.nested {
        list-style: circle inside;
        margin-top: 0.5rem;
      }

      a {
        text-decoration: #0b2b4d underline;
      }
    }
  }

  .tenantServiceWrapper {
    display: flex;
    margin: 2rem 0 0;
    flex-direction: column;

    .copyWrapper {
      flex: 2;
    }
  }
}
