.logoContainer {
  display: block;
  height: 100%;
  margin: auto 1rem;
  max-width: 12rem;

  .logoLink {
    display: flex;
    flex: 0 0 100%;
    height: 100%;
    padding: 0.5rem 0;

    .logo {
      height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
      width: 100%;
    }
  }
}
