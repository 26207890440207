@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.errorWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;
  width: 100%;

  img {
    width: 8rem;
  }

  h2 {
    @include defaultHeader;
    margin-top: 2rem;
    text-align: center;
  }

  p {
    font-family: $default_fontParagraph;
    letter-spacing: 0.025rem;
    line-height: 1.75;
    margin-top: 1rem;
  }
}
