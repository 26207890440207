@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

:global(.mobile) {
  .contactWrapper {
    .column {
      align-items: center;
      justify-content: center;
      min-width: 100%;
      padding: 0;

      &.right {
        min-height: 23rem;
        padding: 0;
      }

      .companyName {
        font-size: 1rem;
      }
    }
  }
}

.contactWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;

  .column {
    display: flex;
    flex: 1 0 50%;
    flex-direction: column;
    margin-bottom: 2rem;
    padding-right: 1rem;

    &.right {
      padding-left: 1rem;
      padding-right: 0;
      text-align: center;
    }

    .companyName {
      color: $contact_headerColor;
      font-size: 1.25rem;
    }
  }
}
