@import './fonts.scss';
@import './colors.scss';
@import './props.scss';
@import './mixins.scss';

html {
  height: 100%;
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
button,
input,
label,
textarea {
  font: $default_font;
}

body {
  background: $default_bodyBackgroundColor;
  color: $default_textColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  user-select: none;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

a {
  text-decoration: none;
}

b,
strong {
  font-weight: $default_fontBoldWeight;
}
