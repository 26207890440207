@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

@keyframes kenBurns {
  from {
    transform: scale(1.1);
    transform-origin: right;
  }
  to {
    transform: scale(1);
  }
}

@keyframes blurIn {
  from {
    filter: blur(5px);
  }
  to {
    filter: blur(0px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
