@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.mainMenuWrapper {
  display: flex;
  flex: 1 1 0;
  flex-flow: row nowrap;
  justify-content: space-evenly;

  .menuItem {
    position: relative;

    &:hover .dropdownContainer {
      visibility: visible;
    }

    .navLink {
      color: $mainMenu_linkColor;

      &:hover {
        color: $mainMenu_linkHoverColor;
      }
    }

    .dropdownContainer {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: calc(#{$navbar_height} - 1rem);
      visibility: hidden;

      .dropdownItem {
        background-color: $mainMenu_background;
        border-bottom: 0.15rem $mainMenu_linkAccent solid;
        border-radius: 0;
        border-right: 0.15rem $mainMenu_linkAccent solid;
        color: $mainMenu_linkColor;
        margin: 0.5rem 0;
        padding: 0.25rem 0.75rem;
        transition: $default_transition;
        white-space: nowrap;

        &:hover {
          background-color: $mainMenu_backgroundHover;
          border-color: $mainMenu_linkHoverAccent;
          box-shadow: $default_shadow;
          color: $mainMenu_linkHoverColor;
          transform: translateY(-0.15rem);

          &::before {
            background: $mainMenu_linkHoverAccent;
          }
        }

        //&::before {
        //  background-color: $mainMenu_linkAccent;
        //  border-radius: 50%;
        //  content: '';
        //  display: block;
        //  height: 0.75rem;
        //  left: -0.425rem;
        //  position: absolute;
        //  transform: translateY(0.425rem);
        //  width: 0.75rem;
        //}
      }
    }
  }
}
