@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

:global(.mobile) {
  .horizontalCardWrapper {
    flex-flow: column;

    .imageContainer {
      width: 100%;
    }
  }
}

.horizontalCardWrapper {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 2rem;
  position: relative;
  transition: $default_transition;
  width: 100%;

  &:hover {
    box-shadow: $default_shadow;
    transform: translateY(-0.15rem);
  }

  &:after {
    bottom: 0;
    color: $horizontalCard_iconColor;
    content: '⇲';
    font-size: 2rem;
    height: 2rem;
    line-height: 2rem;
    position: absolute;
    right: 0;
    text-align: center;
    width: 2rem;
  }

  .imageContainer {
    height: 15rem;
    width: 33%;

    .image {
      @include objectFitImage;
    }
  }

  .cardBody {
    display: flex;
    flex: 1 1 0;
    flex-flow: column;
    padding: 1.5rem;

    .data {
      color: $horizontalCard_textColor;
      font-family: $default_fontParagraph;
      font-size: 0.875rem;
    }

    .title {
      @include lineLimit(2, 1.3);
      color: $horizontalCard_titleColor;
      font-size: 1.25rem;
      margin: 1rem 0;
    }

    .description {
      @include lineLimit(4);
      color: $horizontalCard_textColor;
      font-family: $default_fontParagraph;
      font-size: 0.875rem;
    }

    .linkText {
      color: $horizontalCard_linkTextColor;
      font-size: 0.75rem;
      margin-top: 1rem;
    }
  }
}
