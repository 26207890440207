@import 'src/misc/styles/colors';
@import 'src/misc/styles/mixins';
@import 'src/misc/styles/props';

:global(.mobile) {
  .sliderWrapper {
    .imageContainer {
      height: 33vh;
    }
  }
}

.sliderWrapper {
  margin: 4rem auto 0;
  max-width: calc(100vw - 4rem);

  .imageContainer {
    height: 25rem;

    .image {
      @include objectFitImage;
    }
  }
}
