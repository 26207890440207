@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';

ul {
  font-size: 0.75rem;
  margin: 2rem 0;
  max-width: $default_contentWidth;
  width: 100%;
  display: block;

  li {
    display: inline;
    vertical-align: middle;

    &,
    a {
      color: $breadcrumbs_link;
      vertical-align: middle;

      .homeIcon {
        color: $breadcrumbs_link;
        font-size: 0.75rem;
        vertical-align: middle;
      }
    }

    .arrow {
      &:after {
        color: $breadcrumbs_arrow;
        content: '>';
        margin: 0 0.75rem;
      }
    }
  }
}
