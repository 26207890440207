@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.navBar {
  align-items: center;
  background: $navbar_background;
  display: flex;
  grid-area: nav;
  height: $navbar_height;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: 2;

  &.sticky {
    background: $navbar_backgroundSticky;
    box-shadow: none;
    height: $navbar_stickyHeight;
    position: fixed;
    transition: box-shadow 200ms linear;

    &.open {
      box-shadow: $navbar_shadowColor 0 0.25rem 0.75rem;
    }
  }
}
