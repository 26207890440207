@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

:global(.mobile) {
  .departmentWrapper {
    .sectionWrapper {
      .carousel {
        .imageContainer {
          height: 33vh;
        }
      }
    }
  }
}

.departmentWrapper {
  max-width: 100%;

  .sectionWrapper {
    .imageContainer {
      height: 32rem;
      width: 100%;

      .image {
        @include objectFitImage;
      }
    }

    .sectionTitle {
      @include defaultHeader;
      margin-top: 2rem;
    }

    .paragraph {
      @include topBottomMargins;
      font-family: $default_fontParagraph;
      hyphens: auto;
      letter-spacing: 0.025rem;
      line-height: 1.75;
      margin-top: 1rem;
      text-align: justify;
    }

    .buttonLink {
      @include defaultButton;
      display: block;
      margin: 1rem auto;
    }

    .separator {
      @include separator;
    }
  }
}
