@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.footer {
  align-items: center;
  background: $footer_backgroundColor;
  display: flex;
  flex-direction: column;
  grid-area: footer;
  justify-content: flex-start;
  margin: 5rem auto 0;
  padding: 5rem 0 1rem;
  width: 100%;

  .footerRow {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    max-width: $default_contentWidth;
    width: 100%;

    .footerMenu {
      display: flex;
      flex: 1 1 0;
      flex-flow: row wrap;
      justify-content: space-between;
      padding-left: 2rem;

      .footerColumn {
        display: flex;
        flex: 1 1 0;
        flex-direction: column;
        margin: 0 2rem 2rem 0;
        max-width: 8.375rem;
        min-width: 8.375rem;

        .mainLink {
          border-bottom: $default_solidBorder $footer_linkBorderColor;
          color: $footer_textColor;
          font-size: 0.875rem;
          font-weight: $default_fontBoldWeight;
          margin-bottom: 0.5rem;
          padding-bottom: 0.5rem;
          transition: $default_transition;

          &:hover {
            border-bottom: $default_solidBorder $footer_textColorHover;
            color: $footer_textColorHover;
          }
        }

        .subLink {
          color: $footer_textColor;
          font-family: 'Times New Roman', serif;
          font-size: 0.75rem;
          letter-spacing: 0.075rem;
          margin-bottom: 0.5rem;
          transition: $default_transition;

          &:hover {
            color: $footer_textColorHover;
          }
        }
      }
    }

    .rightColumn {
      max-width: 12.5rem;
      min-width: 7.5rem;
      padding-right: 2rem;

      .crest {
        object-fit: contain;
        width: 100%;
      }
    }
  }

  a.copyrights {
    align-items: inherit;
    color: $footer_copyrightsColor;
    display: inherit;
    flex-direction: inherit;
    font-size: 0.5rem;
    height: inherit;
    justify-content: inherit;
    margin: 2rem auto 1rem;
  }
}
