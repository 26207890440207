@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

:global(.desktop) {
  .sponsorshipWrapper {
    @include cardDeckDesktop;

    .column {
      @include cardDesktop;
      min-width: 19.375rem;

      .columnHeader {
        height: 23.5rem;
      }
    }
  }
}

.pageTitle {
  @include defaultHeader;
}

.paragraph {
  font-family: $default_fontParagraph;
  hyphens: auto;
  letter-spacing: 0.025rem;
  line-height: 1.75;
  margin: 1rem 0;
  text-align: justify;
}

.sponsorshipWrapper {
  @include cardDeckMobile;

  .column {
    @include cardMobile;
    border: $default_solidBorder $press_cardBorder;
    transition: $default_transition;

    &:hover {
      box-shadow: $default_shadow;
      transform: translateY(-0.15rem);
    }

    .columnHeader {
      @include cardHeader;
      height: 13.5rem;

      .cover {
        @include objectFitImage;
      }
    }

    .columnBody {
      @include cardBody;

      .columnTitle {
        color: $press_cardTitle;
      }

      .paragraph {
        color: $press_cardParagraph;
      }
    }
  }
}
