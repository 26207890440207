@import './colors.scss';
@import './props.scss';

@mixin topBottomMargins {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin cardDeckMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  width: 100%;
}

@mixin cardDeckDesktop {
  flex-flow: row wrap;
  justify-content: left;
  width: calc(100% + 1rem);
}

@mixin cardMobile {
  background-clip: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  min-width: 0;
  position: relative;
  user-select: none;
  word-wrap: break-word;
}

@mixin cardDesktop {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  margin-right: 1rem;
}

@mixin cardHeader {
  display: flex;
  margin: 0;
  padding: 0;
}

@mixin cardBody {
  flex: 1 1 auto;
  padding: 1.5rem;
}

@mixin cardFooter {
  padding: 0 1.5rem 1.5rem;
}

@mixin lineLimit($lines: 3, $height: 1.5) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-height: $height;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin defaultButton {
  appearance: none;
  background-color: $defaultButton_background;
  border: $default_solidBorder $defaultButton_border;
  color: $defaultButton_text;
  font-size: 1.25rem;
  padding: 0.75rem 1.5rem;
  text-align: center;
  transition: $default_transition;

  &:hover {
    background-color: $defaultButton_backgroundHover;
    border: $default_solidBorder $defaultButton_borderHover;
    color: $defaultButton_textHover;
  }

  &:active {
    transform: translateY(0.15rem);
  }

  &:disabled {
    background-color: $defaultButton_disabled;
    border-color: $defaultButton_disabled;
    color: $defaultButton_disabledText;
    cursor: not-allowed;
  }
}

@mixin defaultParagraph {
  font-family: $default_fontParagraph;
  hyphens: auto;
  letter-spacing: 0.025rem;
  line-height: 1.75;
  margin-top: 1rem;
  text-align: justify;

  &:first-of-type {
    font-weight: bold;

    &:first-letter {
      @supports (initial-letter: 2) or (-webkit-initial-letter: 2) {
        color: #0b2b4d;
        -webkit-initial-letter: 2;
        initial-letter: 2;
      }
    }
  }
}

@mixin simpleParagraph {
  font-family: $default_fontParagraph;
  hyphens: auto;
  letter-spacing: 0.025rem;
  line-height: 1.75;
  text-align: justify;
}

@mixin defaultHeader {
  color: $default_navy;
  font-size: 2rem;
  hyphens: auto;
  margin: 0 0 2rem;
  word-break: break-word;
}

@mixin defaultSubHeader {
  color: $default_navy;
  font-size: 1rem;
  hyphens: auto;
  margin: 1rem 0;
  word-break: break-word;
}

@mixin objectFitImage {
  height: 100%;
  object-fit: cover;
  object-position: center center;
  width: 100%;
}

@mixin separator {
  height: 1px;
  margin: 4rem auto;
  position: relative;

  &:before {
    background-image: linear-gradient(
      to right,
      transparent,
      $default_navy,
      transparent
    );
    content: '';
    height: 1px;
    left: 5%;
    position: absolute;
    right: 5%;
    top: 0;
    width: 90%;
  }
}
