@import 'src/misc/styles/animations';
@import 'src/misc/styles/props';

.spinnerIcon {
  animation: spin infinite 1s linear 0s normal;
  animation-timing-function: steps(8, start);
  display: block;
  left: calc(50% - 1rem);
  opacity: 0.2;
  position: fixed;
  top: calc(50% - 1rem);
  will-change: transform;
}
