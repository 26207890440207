/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * trajan-pro-3:
 *   - http://typekit.com/eulas/000000000000000000012e92
 *   - http://typekit.com/eulas/000000000000000000012e8f
 *   - http://typekit.com/eulas/000000000000000000012e90
 *   - http://typekit.com/eulas/000000000000000000012e91
 *   - http://typekit.com/eulas/000000000000000000012e93
 *   - http://typekit.com/eulas/000000000000000000012e8e
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-10-14 23:09:42 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=unn3pbk&ht=tk&f=15665.15666.15667.15668.15669.15670&a=16391253&app=typekit&e=css');

@font-face {
  font-display: auto;
  font-family: 'trajan-pro-3';
  font-style: normal;
  font-weight: 400;
  src: url('https://use.typekit.net/af/6799c3/000000000000000000012e92/27/l?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/6799c3/000000000000000000012e92/27/d?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/6799c3/000000000000000000012e92/27/a?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n4&v=3')
      format('opentype');
}

@font-face {
  font-display: auto;
  font-family: 'trajan-pro-3';
  font-style: normal;
  font-weight: 700;
  src: url('https://use.typekit.net/af/183be2/000000000000000000012e8f/27/l?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/183be2/000000000000000000012e8f/27/d?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/183be2/000000000000000000012e8f/27/a?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n7&v=3')
      format('opentype');
}

@font-face {
  font-display: auto;
  font-family: 'trajan-pro-3';
  font-style: normal;
  font-weight: 200;
  src: url('https://use.typekit.net/af/5d2e8c/000000000000000000012e90/27/l?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n2&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/5d2e8c/000000000000000000012e90/27/d?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n2&v=3')
      format('woff'),
    url('https://use.typekit.net/af/5d2e8c/000000000000000000012e90/27/a?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n2&v=3')
      format('opentype');
}

@font-face {
  font-display: auto;
  font-family: 'trajan-pro-3';
  font-style: normal;
  font-weight: 300;
  src: url('https://use.typekit.net/af/bdb813/000000000000000000012e91/27/l?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/bdb813/000000000000000000012e91/27/d?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/bdb813/000000000000000000012e91/27/a?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n3&v=3')
      format('opentype');
}

@font-face {
  font-display: auto;
  font-family: 'trajan-pro-3';
  font-style: normal;
  font-weight: 600;
  src: url('https://use.typekit.net/af/4f40e2/000000000000000000012e93/27/l?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/4f40e2/000000000000000000012e93/27/d?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/4f40e2/000000000000000000012e93/27/a?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n6&v=3')
      format('opentype');
}

@font-face {
  font-display: auto;
  font-family: 'trajan-pro-3';
  font-style: normal;
  font-weight: 900;
  src: url('https://use.typekit.net/af/564dd4/000000000000000000012e8e/27/l?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n9&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/564dd4/000000000000000000012e8e/27/d?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n9&v=3')
      format('woff'),
    url('https://use.typekit.net/af/564dd4/000000000000000000012e8e/27/a?primer=bd2e7279b8e28728e69eb540b5150ce43e5cd0a473612664c65ebd5f9cd4ea9d&fvd=n9&v=3')
      format('opentype');
}
