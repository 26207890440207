@import 'src/misc/styles/animations';
@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.imageSectionWrapper {
  display: block;
  height: 33vh;
  max-height: 33vh;
  min-height: 33vh;
  overflow: hidden;
  position: relative;

  .image {
    @include objectFitImage;
    animation: fadeIn 750ms cubic-bezier(0.23, 1, 0.32, 1) forwards 1 10ms,
      blurIn forwards 1s ease-in-out, kenBurns forwards 15s ease-in-out;
    filter: grayscale(100);
    transition: filter 1000ms;

    &.inView {
      filter: grayscale(0);
    }

    &.bottom {
      object-position: 50% bottom;
    }

    &.top {
      object-position: 50% top;
    }
  }
}
