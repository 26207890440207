@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';

.langToggle {
  align-items: center;
  border: $default_solidBorder $toggle_borderColor;
  border-radius: 0.15rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: auto 1rem;
  padding: 0.125rem;
  position: relative;

  .switch {
    background-color: $toggle_switchBackgroundColor;
    border: $default_solidBorder $toggle_borderColor;
    border-radius: 0.15rem;
    height: 1rem;
    left: 0.25rem;
    position: absolute;
    top: 0.125rem;
    transition: $default_transition;
    width: 1.25rem;
    z-index: -1;

    &.two {
      left: 1.75rem;
    }

    &.three {
      left: 3.25rem;
    }
  }

  label {
    cursor: pointer;
    padding: 0.25rem;

    input {
      display: none;
    }

    .langTag {
      font-size: 0.5rem;
      line-height: 1;
      text-align: center;
      transition: font-weight 250ms 250ms;
      width: 1rem;
    }
  }

  input[type='radio'] {
    &:checked {
      & ~ .langTag {
        font-weight: $default_fontBoldWeight;
      }
    }
  }
}

:global(.mobile) {
  .langToggle {
    flex-direction: column;

    .switch {
      left: 0.25rem;
      top: 0.125rem;

      &.two {
        left: 0.25rem;
        top: 1.15rem;
      }

      &.three {
        left: 0.25rem;
        top: 2.1rem;
      }
    }
  }
}
