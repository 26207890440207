@font-face {
  font-display: block;
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('./icomoon.eot?f1xm3z');
  src: url('./icomoon.eot?f1xm3z#iefix') format('embedded-opentype'),
  url('./icomoon.ttf?f1xm3z') format('truetype'),
  url('./icomoon.woff?f1xm3z') format('woff'),
  url('./icomoon.svg?f1xm3z#icomoon') format('svg');
}

[class^='icon-'], [class*=' icon-'] {
  font-family: 'icomoon' !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.icon-home:before {
  content: "\e900";
}
.icon-phone:before {
  content: "\e942";
}
.icon-location2:before {
  content: "\e948";
}
.icon-printer:before {
  content: "\e954";
}
.icon-bubble:before {
  content: "\e96b";
}
.icon-spinner:before {
  content: "\e97a";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-checkmark2:before {
  content: "\ea11";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-linkedin:before {
  content: "\eac9";
}
