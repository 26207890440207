@import 'src/misc/styles/animations';
@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.header {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 2 * #{$navbar_height});
  overflow: hidden;

  .bgImage {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    min-height: inherit;
    opacity: 1;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;

    &.lg {
      animation: kenBurns forwards 15s ease-in-out;
      background-image: url('./../../../misc/images/backgrounds/bg_main-01.jpg');
    }
  }

  .headerContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: $default_contentWidth;
    min-height: inherit;
    padding: $navbar_height 1.5rem 1.5rem;
    text-align: center;

    img {
      width: 200px;
    }

    h1 {
      color: white;
      font-size: 3rem;
      font-weight: $default_fontBoldWeight;
      margin-top: 1rem;
      text-shadow: 2px 2px #11314e;
    }
  }
}

:global(.mobile) {
  .header {
    .headerContent {
      h1 {
        font-size: 2rem;
      }
    }
  }
}
