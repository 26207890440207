@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.grid {
  display: grid;
  grid-template-areas: 'nav' 'main' 'footer';
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;

  .content {
    grid-area: main;
    height: 100%;
    margin-top: $navbar_height;
  }
}
