@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

.sectionsWrapper {
  max-width: inherit;
  width: inherit;

  .pageTitle {
    @include defaultHeader;
    margin-top: 4rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .buttonLink {
    @include defaultButton;
    display: block;
    margin: 2rem 0 0;
  }
}
