@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';
@import 'src/misc/styles/mixins';

:global(.mobile) {
  .rentWrapper {
    .locationsWrapper {
      .column {
        align-items: center;
        justify-content: center;
        min-width: 100%;
        padding: 0;

        &:last-child {
          min-width: 100%;
          padding-left: 0;
        }
      }
    }
  }
}

.rentWrapper {
  max-width: inherit;
  width: inherit;

  .pageTitle {
    @include defaultHeader;
  }

  .paragraph {
    @include simpleParagraph;
    margin: 1rem 0;
  }

  .locationsWrapper {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    .column {
      align-items: center;
      display: flex;
      flex: 1 1 50%;
      flex-direction: column;
      justify-content: center;
      padding-right: 1rem;

      &:last-child {
        padding-left: 1rem;
        padding-right: 0;
        text-align: center;
      }

      &.mapContainer {
        min-height: 30rem;
        width: 100%;
      }
    }
  }

  .linkDescription {
    margin: 2rem 0 0;
  }

  .buttonLink {
    @include defaultButton;
    display: block;
    margin: 2rem 0 0;
  }
}
