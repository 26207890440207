@import 'src/misc/styles/colors';

.marker {
  background-color: $mapMarker_background;
  border: $mapMarker_border 3px solid;
  border-radius: 50%;
  height: 0.675rem;
  position: relative;
  width: 0.675rem;
  z-index: 1;

  &:hover .popup {
    display: flex;
  }

  .popup {
    align-items: center;
    background-color: $mapMarker_background;
    border: $mapMarker_border 1px solid;
    border-radius: 0.5rem;
    display: none;
    flex-flow: row nowrap;
    left: -4rem;
    padding: 0.5rem;
    position: absolute;
    top: -5rem;
    z-index: 2;

    &::after {
      background-color: $mapMarker_background;
      border-bottom: $mapMarker_border 1px solid;
      border-right: $mapMarker_border 1px solid;
      bottom: calc(-0.5rem - 1px);
      content: '';
      display: block;
      height: 1rem;
      left: calc(50% - 0.25rem);
      position: absolute;
      transform: rotate(45deg);
      width: 1rem;
      z-index: 3;
    }

    img {
      margin-right: 0.5rem;
      width: 2rem;
    }

    p {
      font-family: trajan-pro-3, serif;
      font-size: 0.75rem;
      margin-top: 0.25rem;
    }
  }
}
