@import 'src/misc/styles/colors';
@import 'src/misc/styles/props';

.address {
  margin-top: 1.5rem;
  flex: 1;

  p {
    font-family: $default_fontParagraph;
    line-height: 1.3;
  }

  .mailLink {
    color: $contact_linkColor;
    font-family: $default_fontParagraph;
    margin-top: 0.5rem;

    &:hover {
      font-weight: $default_fontBoldWeight;
    }
  }
}
